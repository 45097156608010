<template>
  <surround-box 
    specialColor="#0d4257"
    background="linear-gradient(270deg, rgba(21, 101, 182, 0) 0%, #3B7AC2 50%, rgba(21, 101, 182, 0) 95%)" title="消息预警">
    <div class="content-box">
      <div class="info-item" v-for="(item, index) in data" :key="index">
        <div class="info-title">
          <span><img src="/imgs/warn.png" /></span>
          <span>{{ item.content }}</span>
        </div>
        <div class="time-status">
          <span>{{ item.createTime }}</span>
          <span class="no-read">{{ item.statusName }}</span>
        </div>
      </div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";

export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    getList() {
      this.$post(this.$api.PRODUCE_MAPINFO.WARNING, {
        isAdmin: "1",
        alarmType: 2,
      }).then((res) => {
        this.data = res.list;
        // console.log(res);
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang='less' scoped>
.content-box {
  width: 100%;
  height: 220px;
  position: relative;
  color: #fff;
  padding: 4px 16px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .info-item {
    padding: 8px 0px;
    .info-title {
      display: flex;
      align-items: flex-start;
      span:first-child {
        padding-right: 8px;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
    .time-status {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .no-read {
        color: #00ffdc;
      }
    }
  }
}
</style>