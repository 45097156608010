<template>
  <div class="outer-box">
    <transition name="name-fade-in-fdsggdsga">
      <div v-show="isScroll" @click="zhankai" class="scroll-bar">
        粪污生产量统计
      </div>
    </transition>
    <transition name="chart-fade-fdsggdsga">
      <div v-show="!isScroll" class="chart-fade-leave-box">
        <div class="chart-fade-leave-inner-box">
          <surround-box
            specialColor="#0d455c"
            background="linear-gradient(270deg, rgba(21, 101, 182, 0) 0%, #3B7AC2 50%, rgba(21, 101, 182, 0) 95%)"
            title="粪污生产量统计"
            style="width: 100%"
          >
            <div class="chart-box">
              <div ref="chart" class="chart"></div>
              <div class="unit">单位:万吨</div>
              <div @click="shouqi" class="scroll-out">收起</div>
            </div>
          </surround-box>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";
import * as echarts from "echarts";

export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      data: [
        {
          year: "2016",
          num: 7.8,
        },
        {
          year: "2017",
          num: 12.9,
        },
        {
          year: "2018",
          num: 13.2,
        },
        {
          year: "2019",
          num: 9.2,
        },
        {
          year: "2020",
          num: 7.9,
        },
      ],
      params: {
        type: "year",
        startTime: "2015-01-01 00:00:00",
        endTime: "2021-12-31 00:00:00",
      },
      //   是否收起
      isScroll: false,
    };
  },
  methods: {
    shouqi() {
      this.isScroll = true;
    },
    zhankai() {
      this.isScroll = false;
    },
    //   查询数据
    getData() {
      return new Promise((resolve) => {
        this.$post(this.$api.PRODUCE_MAPINFO.PRODUCE, this.params).then(
          (res) => {
            let result = [];
            for (let key of Object.keys(res)) {
              result.push({
                year: key,
                num: (+res[key] / 10000).toFixed(2),
              });
            }
            resolve(result);
          }
        );
      });
    },
    //   初始化
    initChart() {
      let chart = echarts.init(this.$refs.chart);
      let options = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          containLabel: true,
          top: 48,
          left: 16,
          right: 32,
          bottom: 16,
        },
        xAxis: {
          data: this.data.map((item) => item.year),
          axisLabel: {
            color: "#fff",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: ["#fff"],
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["rgba(255,255,255,0.5)"],
              type: "dashed",
            },
          },
        },
        yAxis: {
          axisLabel: {
            color: "#fff",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["rgba(255,255,255,0.5)"],
              type: "dashed",
            },
          },
        },
        series: [
          {
            type: "bar",
            data: this.data.map((item) => item.num),
            barWidth: 30,
            label: {
              show: true,
              position: "top",
              color: "#fff",
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#947DFF",
                },
                {
                  offset: 1,
                  color: "#23A6F2",
                },
              ]),
            },
          },
        ],
      };
      chart.setOption(options);
    },
  },
  mounted() {
    this.getData().then((list) => {
      this.data = list;
      this.initChart();
    });
  },
};
</script>
<style lang='less' scoped>
.outer-box {
  width: 100%;
  height: 260px;
  position: relative;
  .scroll-bar {
    cursor: pointer;
    width: 36px;
    height: 260px;
    white-space: pre-wrap;
    position: absolute;
    left: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    z-index: 201;
    font-size: 16px;
    padding: 8px;
    border-radius: 4px;
  }
  .chart-fade-leave-box {
    width: 510px;
    height: 100%;
    position: relative;
    overflow: hidden;
    .chart-fade-leave-inner-box {
      // width: 400px;
      width: 500px;
      height: 260px;
      position: absolute;
      left: 0px;
      top: 0px;
      .chart-box {
        width: 100%;
        height: 220px;
        position: relative;
        .chart {
          width: 100%;
          height: 200px;
        }
        .unit {
          position: absolute;
          left: 16px;
          top: 12px;
          color: #fff;
          font-weight: bold;
        }
        .scroll-out {
          cursor: pointer;
          position: absolute;
          right: 0px;
          top: 50%;
          transform: translateY(-50%);
          color: #fff;
          width: 22px;
          font-size: 14px;
          padding: 8px 4px;
          white-space: pre-wrap;
          background: rgba(0, 0, 0, 0.5);
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
<style>
.name-fade-in-fdsggdsga-enter-active {
  transition: opacity 0.5s;
}
.name-fade-in-fdsggdsga-enter {
  opacity: 0;
}

.chart-fade-fdsggdsga-enter-active {
  animation: chart-fade-fdsggdsga-enter-animation 0.3s linear;
}
.chart-fade-fdsggdsga-leave-active {
  animation: chart-fade-fdsggdsga-enter-animation 0.2s linear reverse;
}
@keyframes chart-fade-fdsggdsga-enter-animation {
  0% {
    width: 0px;
  }
  50% {
    width: 200px;
  }
  100% {
    width: 400px;
  }
}
</style>